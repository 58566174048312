import React from "react";
import styled from "styled-components";

import TopBoarder from "./global/borders/topboarder"
import LeftBoarder from "./global/borders/leftborder"
import RightBoarder from "./global/borders/rightborder"
import BottomBoarder from "./global/borders/bottomboarder"

const SiteStyled = styled.div`
    overflow: hidden;
    .site {
        padding: 0;
        margin: 0;
    }
`;

export default function Layout(props) {
    return (
        <SiteStyled>
            <div className="site">
                <TopBoarder />
                <LeftBoarder />
                <RightBoarder />
                <BottomBoarder />
                {props.children}
            </div>
        </SiteStyled>
    )
}