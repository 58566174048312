import React from "react";
import styled from "styled-components";

import instagramblack from "../../../images/icons/instagram-black.png";
import linkedinblack from "../../../images/icons/linkedin-black.png";
import mediumblack from "../../../images/icons/medium-black.png";
import githubblack from "../../../images/icons/github-black.png";

const BorderStyled = styled.div`
    overflow: hidden;
    .canvas {
        background-color: var(--backgroundb);
        width: var(--marginborder);
        height: 100vh;
        position: fixed;
        right: 0;
        z-index: 50;
        align-content: center;
        flex-direction: column;
        justify-content: center;
        display: none; 
        @media (min-width: 1023px) {
            display: flex; 
        }
    }
    .insta{
        margin: 1.5vh;
        width: 2vh;
        height: 2vh;
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat; 
        background-image: url(${instagramblack});
        background-size: 100% 100%;
        box-sizing: border-box;
    }
    .linkedin{
        margin: 1.5vh;
        width: 2vh;
        height: 2vh;
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat; 
        background-image: url(${linkedinblack});
        background-size: 100% 100%;
        box-sizing: border-box;
    }
    .medium{
        margin: 1.5vh;
        width: 2vh;
        height: 2vh;
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat; 
        background-image: url(${mediumblack});
        background-size: 100% 100%;
        box-sizing: border-box;
    }
    .github{
        margin: 1.5vh;
        width: 2vh;
        height: 2vh;
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat; 
        background-image: url(${githubblack});
        background-size: 100% 100%;
        box-sizing: border-box;
    }
`;

const RightBoarder = ({ children }) => {

    return (
        <BorderStyled>
            <div className="canvas">
                <a href="https://www.linkedin.com/in/alex-sze/" target="_blank">
                    <div className="linkedin" />
                </a>
                {/* <a href="https://www.instagram.com/alexszezyy/">
                    <div className="insta" />
                </a> */}
                <a href="https://medium.com/@alexyysze" target="_blank">
                    <div className="medium" />
                </a>
                <a href="https://github.com/AlexSze" target="_blank">
                    <div className="github" />
                </a>
            </div>
        </BorderStyled>
    );
};

export default RightBoarder