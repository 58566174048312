import React from "react";
import styled from "styled-components";

const BorderStyled = styled.div`
    overflow: hidden;
    .canvas {
        background-color: var(--backgroundb);
        width: var(--marginborder);
        height: 100vh;
        position: fixed;
        left: 0;
        z-index: 45;
        display: none; 
        @media (min-width: 1023px) {
            display: block; 
        }
    }
`;

const LeftBoarder = ({ children }) => {
    return (
        <BorderStyled>
            <div className="canvas" />
        </BorderStyled>
    );
};

export default LeftBoarder