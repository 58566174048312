import React from "react";
import styled from "styled-components";

const BorderStyled = styled.div`
    overflow: hidden;
    .canvas {
        background-color: var(--backgroundb);
        width: 100vw;
        height: var(--marginborder);
        position: fixed;
        bottom: 0;
        z-index: 45;
        display: none; 
        @media (min-width: 1023px) {
            display: block; 
        }
    }
    .message{
        left: 50vw;
        position: fixed;
        color: red;
        background-color: transparent;
        transform: translate(-50%, 50%);
    }
`;

const BottomBoarder = ({ children }) => {
    return (
        <BorderStyled>
            <div className="canvas">
                <div className="message">

                </div>
            </div>
        </BorderStyled>
    );
};

export default BottomBoarder