import React, { useState, useEffect, useCallback } from 'react';

import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useTranslation } from 'react-i18next';

gsap.registerPlugin(ScrollTrigger)

const FooterStyled = styled.div`
    overflow: hidden;
    .footer {
        height: 10vh;
        width:100vw;
        background-color: var(--themecolora);
        color: var(--backgroundb);
        position: relative;
        text-align: center;
        font-size: var(--fontsizefooter);
        padding-top: 2vh;
    }
`;

function FooterSection({ theref }) {
    const [t, i18n] = useTranslation();

    return (
        <FooterStyled>
            <div className="footer" ref={theref}>
                {t('Footer.footer')}
            </div>
        </FooterStyled>
    )
}

export default FooterSection;