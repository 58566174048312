import React, { useState, useEffect, useCallback } from 'react';

import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from 'react-i18next';

import useWindowSize from '../global/parts/windowsSize';
import { ButtonStyle } from '../global/parts/buttons';
import startupcover from '../../images/startupcover.png';

gsap.registerPlugin(ScrollTrigger)

const StartupsSectionStyled = styled.div`
    overflow: hidden;
    @media (min-width: 1023px) {
        .containermain{
            display: flex;
            flex-direction: column;
        }
    }
    .startupsectioncanvas {
        width: 100%;
        height: 100vh;
        background-color: var(--backgrounda);
        position: relative;
    }
    .startuppicture{
        top: 50vh;
        transform:translate(0, -50%);
        position:relative;
        background-color: var(--themecolorc);
        background-position:center center;
        background-image: url(${startupcover});
        background-size: cover;
        background-repeat: no-repeat;
        box-sizing: border-box;
        opacity: 1;
        z-index: 1;
        object-fit: cover;
        width: 100vw;
        height: 70vh;
        box-shadow: 0 30px 40px var(--bordertext);
        @media (min-width: 1023px) {
            background-position:center center;
            position: absolute;
            width: 38vw;
            height: 70vh;
            right: var(--margin);
            border-radius: 30px;
        }
    }
    .contentcontainer{
        position: relative;
        top: 0;
        left: 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        flex-wrap: center;
        opacity: 0;
        z-index: 5;
        padding: var(--marginhalf) var(--marginhalf) var(--marginhalf) var(--marginhalf);
        @media (min-width: 1023px) {
            top: 10vh;
            position: absolute;
            background-color: transparent;
            padding: var(--margin) 15vh var(--margin) var(--margin);
            width: 50vw;
            height: 100vh;
        }
    }
    .startupword{
        text-align: center;
        font-size: var(--fontsizetitlec);
        font-weight: bold;
        position: relative;
        color: var(--texta);
        background-color: transparent;
        padding-bottom: 3vh;
        @media (min-width: 1023px) {
            text-align: left;
            font-size: var(--fontsizetitlea);
        }
    }
    .startupcontent{
        text-align: center;
        font-size: var(--fontsizecontentc);
        font-weight: 10;
        position: relative;
        color: var(--backgroundb);
        background-color: transparent;
        top: 7vh;
        @media (min-width: 1023px) {
            text-align: left;
        }
    }
    .button{
        position: relative;
    }
`;

function StartupsSection() {
    const size = useWindowSize();
    const [t, i18n] = useTranslation();

    gsap.to('.contentcontainer', {
        scrollTrigger: {
            markers: false,
            trigger: ".startupword",
            toogleActions: "restart pause reverse pause",
            start: "top 50%",
            end: "+=5%",
            scrub: 1,
        },
        opacity: 1,
    })

    return (
        <StartupsSectionStyled>
            <div className="startupsectioncanvas" >
                <div className="containermain">
                    <div className="contentcontainer">
                        <div className="startupcontent">
                            <div className="startupword">
                                {t('StartupSection.title')}
                            </div>
                            <i>{t('StartupSection.descriptiona')}</i>
                            <br />
                            {t('StartupSection.descriptionb')}
                            <br />
                            <br />
                            <br />
                            <br />
                            <a href='/startups/'>
                                <div className="button">
                                    <ButtonStyle>
                                        {t('StartupSection.learnmore')}
                                    </ButtonStyle>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="startuppicture" />
            </div>
        </StartupsSectionStyled >
    )
}

export default StartupsSection;