import React, { Component, useRef, useEffect } from "react";

import LandingSection from './landingSection';
import StartupsSection from './startupSection';
import ProjectsSection from './projectSection';
import FooterSection from "../global/footer";
import AboutMe from './aboutmeSection';
import ContactMe from './contactmeSection';

function LandingPage() {
    //for element jumping
    const aboutMeRef = useRef(null);
    const contactRef = useRef(null);
    const executeAboutMeScroll = () => aboutMeRef.current.scrollIntoView({ behavior: "smooth" });
    const executeContactScroll = () => contactRef.current.scrollIntoView({ behavior: "smooth" });

    return (
        <div>
            <LandingSection jumpToAboutMe={executeAboutMeScroll} jumpToContactMe={executeContactScroll} />
            <AboutMe theref={aboutMeRef} jumpToBottom={executeContactScroll} />
            <StartupsSection />
            <ProjectsSection />
            <ContactMe />
            <FooterSection theref={contactRef} />
            {/* <div style={{ height: '100vh', position: 'relative', backgroundColor: "var(--themecolora)" }} /> */}
        </div>
    )

}

export default LandingPage;