import React, { useState, useEffect, useCallback } from 'react';

import styled from "styled-components";
import { useTranslation } from 'react-i18next';

import useWindowSize from '../global/parts/windowsSize';
import { ButtonStyle } from '../global/parts/buttons';
import { FaLongArrowAltLeft } from 'react-icons/fa';

const ProjectDetailStyled = styled.div`
    overflow: hidden;
    .canvas {
        margin: 0;
        width: 100vw;
        min-height: 100vh;
        background-color: var(--backgroundc);
        position: relative;
        box-sizing: border-box;
        z-index: 0;
    }
    .sitenamecontainer {
        background-color: transparent;
        color: var(--textb);
        font-size: var(--fontsizeborder);
        font-weight: bold;
        text-align: right;
        position: relative;
        z-index: 5;
        padding: var(--marginborder);
        padding-bottom: 0;
        margin: auto;
        margin-right: 0px;
        width: 25vw;
        @media (min-width: 1023px) {
            display: none;
        }
    }
    .sitename{
        background-color: transparent;
        color: var(--textb);
    }
    .projectdetailname{
        text-align: left;
        font-size: var(--fontsizetitleb);
        font-weight: 500;
        position: relative;
        color: var(--textb);
        background-color: transparent;
        opacity: 1;
        z-index: 5;
        width: 77vw;
        height: 50vh;
        top: 8vh;
        padding: var(--marginborder);
        @media (min-width: 1023px) {
            padding: var(--margin);
            font-size: var(--fontsizetitlea);
        }
    }
    .projectdetailshortdesc{
        text-align: left;
        font-size: var(--fontsizecontentb);
        font-weight: 3;
        position: absolute;
        color: var(--textb);
        background-color: transparent;
        margin: 1vh;
        width: 77vw;
        opacity: 1;
        z-index: 5;
        @media (min-width: 1023px) {
            font-size: var(--fontsizecontenta);
        }
    }
    /* ratio 2880*1638 */
    .projectpicture{
        min-width: 100vw;
        min-height: 56.8vw;
        position: relative;
        background-image: url(${props => props.img});
        background-color: transparent;
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;
        box-sizing: border-box;
        opacity: 1;
        z-index: 1;
        top: -13vh;
        @media (min-width: 1023px) {
            min-width: 70vw;
            min-height: 39.8125vw;
            margin-left: 30vw;
            border-radius: 30px;
        }
    }
    .projectdetaildesc{
        text-align: center;
        font-size: var(--fontsizecontentb);
        font-weight: 20;
        position: relative;
        color: var(--textb);
        background-color: transparent;
        padding: var(--marginborder);
        opacity: 1;
        z-index: 3;
        object-fit: cover;
        top: -13vh;
        @media (min-width: 1023px) {
            padding: var(--margin);
            padding-bottom: var(--marginhalf);
        }
    }
    .button{
        margin-top: 7vh;
        margin-bottom: 4vh;
    }
    .arrowback{
        position: relative;
        background-color: transparent;
        transition: transform 0.3s ease;
        transform: translateX(0vw);
        top: 8.3vh;
        left: var(--marginborder);
        font-size: 3vh;
        z-index: 6;
        width: 20vw;
        background-color: transparent;
        color: var(--textb);
        &:hover{
            transform: translateX(-1vw);
        }
        @media (min-width: 1023px) {
            top: 13vh;
            left: var(--margin);
            font-size: 5vh;
        }
    }
`;

function ProjectDetailPage({ projectdetailname, projectdetailshortdesc, projectdetaildesc, coverimg, projectsite }) {
    const size = useWindowSize();
    const [t, i18n] = useTranslation();

    return (
        <ProjectDetailStyled img={coverimg}>
            <div className="canvas">
                <a href="/projects" style={{ textDecoration: "none" }}>
                    <div className=" arrowback">
                        <FaLongArrowAltLeft />
                    </div>
                </a>
                <div className="sitenamecontainer">
                    <a href="/" style={{ textDecoration: "none" }}>
                        <div className='sitename'>
                            {t('TopBorder.SiteName')}
                        </div>
                    </a>
                </div>
                <div className="projectdetailname">
                    {projectdetailname}
                    <div className="projectdetailshortdesc">
                        {projectdetailshortdesc}
                    </div>
                </div>
                <div className="projectpicture" />
                <div className="projectdetaildesc">
                    {projectdetaildesc}
                    <br />
                    <a href={projectsite} target="_blank">
                        <div className='button'>
                            <ButtonStyle>{t('ProjectDetailPage.visit')}</ButtonStyle>
                        </div>
                    </a>
                </div>
            </div>
        </ProjectDetailStyled>
    )
}

export default ProjectDetailPage;