import styled from "styled-components";

export const ButtonStyle = styled.button`
    position:relative;
    background-color:var(--themecolora);
    color:var(--backgroundb);
    padding:7px 8px;
    width: 35vw;
    border: none;
    border-radius: 8px;
    z-index: 5;
    text-decoration: none;

    &:hover {
        background-color:var(--backgroundb);
        color:var(--themecolora);
    }

    @media (min-width: 1023px) {
        width: 10vw;
    }
`;