import React from "react";
import styled from "styled-components";

const LoadingPageStyled = styled.div`
    overflow: hidden;
    .canvas {
        padding: 0;
        margin: 0;
        background-color: var(--backgrounda);
        height: 100vh;
        width: 100vw;
    }
    .word{
        position: relative;
        font-size: var(--fontsizetitleb);
        font-weight: bold;
        text-align: center;
        top: 50vh;
        color: var(--texta);
        transform: translate(0, -50%);
        box-sizing: border-box;
        opacity: 1;
        margin-left: var(--marginhalf);
        margin-right: var(--marginhalf);
        @media (min-width: 1023px) {
            font-size: var(--fontsizetitlea);
        }
    }
`;

const StyledSpinner = styled.svg`
    animation: rotate 2s linear infinite;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;
    
    & .path {
      stroke: var(--backgroundb);
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
    
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
    @keyframes dash {
      0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
      }
    }
`;


export default function LoadingPage(props) {
  return (
    <LoadingPageStyled>
      <div className="canvas">
        <div className="word">
          <StyledSpinner viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </StyledSpinner>
        </div>
      </div>
    </LoadingPageStyled>
  )
}