import React, { useState, useEffect, useCallback } from 'react';

import styled from "styled-components";
import { useTranslation } from 'react-i18next';

import useWindowSize from '../global/parts/windowsSize';
import { FaLongArrowAltLeft } from 'react-icons/fa';

const ProjectLandingPageStyled = styled.div`
    overflow: hidden;
    .canvas {
        margin: 0;
        width: 100vw;
        min-height: 130vh;
        background-color: var(--bordertext);
        position: absolute;
        padding: var(--marginhalf);
        padding-top: var(--marginborder);
        box-sizing: border-box;
        @media (min-width: 1023px) {
            padding: var(--margin);
        }
    }
    .sitenamecontainer {
        background-color: transparent;
        color: var(--backgroundb);
        font-size: var(--fontsizeborder);
        font-weight: bold;
        text-align: right;
        position: relative;
        z-index: 5;
        top: 0vh;
        right: -4vh;
        padding-bottom: var(--marginhalf);
        margin: auto;
        margin-right: 0px;
        width: 25vw;
        @media (min-width: 1023px) {
            display: none;
        }
    }
    .sitename{
        background-color: transparent;
        color: var(--backgroundb);
    }
    .projectname{
        text-align: left;
        font-size: var(--fontsizetitlec);
        font-weight: 20;
        position: relative;
        color: var(--backgroundb);
        background-color: transparent;
        margin-bottom: var(--marginhalf);
        opacity: 1;
        transition: transform 0.3s ease;
        transform: translateX(0px);
        &:hover{
            transform: translateX(5vw);
        }
        @media (min-width: 1023px) {
            font-size: var(--fontsizetitlea);
        }
    }
    .projectshortdesc{
        text-align: left;
        font-size: var(--fontsizecontentc);
        font-weight: 20;
        position: absolute;
        color: #ed6459;
        background-color: transparent;
        margin: 1vh;
        opacity: 1;
        @media (min-width: 1023px) {
            font-size: var(--fontsizecontenta);
        }
    }
    .arrowback{
        position: relative;
        background-color: transparent;
        transition: transform 0.3s ease;
        transform: translateX(0vw);
        top: 3.3vh;
        left: -4vh;
        font-size: 3vh;
        z-index: 6;
        width: 20vw;
        background-color: transparent;
        color: var(--backgroundb);
        &:hover{
            transform: translateX(-1vw);
        }
        @media (min-width: 1023px) {
            top: -5vh;
            left: - var(--margin);
            font-size: 5vh;
        }
    }
`;

function ProjectLandingPage() {
    const size = useWindowSize();
    const [t, i18n] = useTranslation();

    return (
        <ProjectLandingPageStyled>
            <div className="canvas">
                <a href="/" style={{ textDecoration: "none" }}>
                    <div className=" arrowback">
                        <FaLongArrowAltLeft />
                    </div>
                </a>
                <div className="sitenamecontainer">
                    <a href="/" style={{ textDecoration: "none" }}>
                        <div className='sitename'>
                            {t('TopBorder.SiteName')}
                        </div>
                    </a>
                </div>
                <a href="/projects/dscsite" style={{ textDecoration: 'none' }}>
                    <div className="projectname">
                        {t('ProjectLandingPage.dscsite')}
                        <div className="projectshortdesc">
                            {t('ProjectLandingPage.dscsitedes')}
                        </div>
                    </div>
                </a>
                <a href="/projects/grc" style={{ textDecoration: 'none' }}>
                    <div className="projectname">
                        {t('ProjectLandingPage.grc')}
                        <div className="projectshortdesc">
                            {t('ProjectLandingPage.grcdes')}
                        </div>
                    </div>
                </a>
                <a href="/projects/ffrabbit" style={{ textDecoration: 'none' }}>
                    <div className="projectname">
                        {t('ProjectLandingPage.ffrabbit')}
                        <div className="projectshortdesc">
                            {t('ProjectLandingPage.ffrabbitdes')}
                        </div>
                    </div>
                </a>
                <a href="/projects/theportfolio" style={{ textDecoration: 'none' }}>
                    <div className="projectname">
                        {t('ProjectLandingPage.theportfolio')}
                        <div className="projectshortdesc">
                            {t('ProjectLandingPage.theportfoliodes')}
                        </div>
                    </div>
                </a>
                <a href="/projects/spaceinvader" style={{ textDecoration: 'none' }}>
                    <div className="projectname">
                        {t('ProjectLandingPage.spaceinvader')}
                        <div className="projectshortdesc">
                            {t('ProjectLandingPage.spaceinvaderdes')}
                        </div>
                    </div>
                </a>
                <a href="/projects/mymarioplan" style={{ textDecoration: 'none' }}>
                    <div className="projectname">
                        {t('ProjectLandingPage.mymarioplan')}
                        <div className="projectshortdesc">
                            {t('ProjectLandingPage.mymarioplandes')}
                        </div>
                    </div>
                </a>
                <a href="/projects/pacman" style={{ textDecoration: 'none' }}>
                    <div className="projectname">
                        {t('ProjectLandingPage.pacman')}
                        <div className="projectshortdesc">
                            {t('ProjectLandingPage.pacmandes')}
                        </div>
                    </div>
                </a>
            </div>
        </ProjectLandingPageStyled >
    )
}

export default ProjectLandingPage;