import React, { useEffect } from "react"
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Layout from "./components/layout"
import LandingPage from "./components/landingPage/landingPage"
import AboutMePage from "./components/aboutmePage";
import ContactPage from "./components/contactPage";
import StartupLandingPage from "./components/startups/startupLanding";
import ProjectLandingPage from "./components/projects/projectLanding";
import ProjectDetailPage from "./components/projects/projectdetail";
import PicarePage from "./components/startups/picare";
import DearFansPage from "./components/startups/dearfans";
import UnknownPage from "./components/unknownpage";

import dscsite from './images/dscsite.png';
import spaceinvader from './images/space-invader.png';
import ffrabbit from './images/ffrabbit.png';
import theportfolio from './images/theportfolio.png';
import grc from './images/grc.png';
import mymarioplan from './images/mymarioplan.jpg';
import pacman from './images/pacman.jpg';

import { useTranslation } from 'react-i18next';

import ReactGA from 'react-ga';

function App() {
  // must add!! otherwise it crash css
  const [t, i18n] = useTranslation();

  useEffect(() => {
    ReactGA.initialize('UA-163404335-4');

    //to report page view
    ReactGA.pageview(window.location.pathname);
  }, [])

  return (
    <Layout>
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route exact path='/aboutme/' component={AboutMePage} />
          <Route exact path='/contact/' component={ContactPage} />
          <Route exact path='/startups/' component={StartupLandingPage} />
          <Route exact path='/projects/' component={ProjectLandingPage} />
          <Route exact path='/projects/dscsite' component={() =>
            <ProjectDetailPage
              projectdetailname={t('ProjectLandingPage.dscsite')}
              projectdetailshortdesc={t('ProjectDetailPage.dscsiteshortdesc')}
              projectdetaildesc={[t('ProjectDetailPage.dscsitedesc'), <br />, <br />, <br />, <b>Time-Cost: 3hrs</ b>, <br />, <b>Skills: Javascript, CSS, HTML, Github Hosting, Rapid development</b>]}
              coverimg={dscsite}
              projectsite="https://alexsze.github.io/DSC-HKUST_official_website/"
            />
          } />
          <Route exact path='/projects/grc' component={() =>
            <ProjectDetailPage
              projectdetailname={t('ProjectLandingPage.grc')}
              projectdetailshortdesc={t('ProjectDetailPage.grcshortdesc')}
              projectdetaildesc={[t('ProjectDetailPage.grcdesc'), <br />, <br />, <br />, <b>Time-Cost: 1week</ b>, <br />, <b>Skills: PHP, CSS, GCP, Wordpress, Woocommerce, Github Hosting, Rapid development, E-commerce</b>]}
              coverimg={grc}
              projectsite="https://www.geekrabbitcomputer.com/"
            />
          } />
          <Route exact path='/projects/ffrabbit' component={() =>
            <ProjectDetailPage
              projectdetailname={t('ProjectLandingPage.ffrabbit')}
              projectdetailshortdesc={t('ProjectDetailPage.ffrabbitshortdesc')}
              projectdetaildesc={[t('ProjectDetailPage.ffrabbitdesc'), <br />, <br />, <br />, <b>Time-Cost: 1week</ b>, <br />, <b>Skills: Wordpress, Ads, Hosting, SEO</b>]}
              coverimg={ffrabbit}
              projectsite="https://alexsze.github.io/ffrabbit/"
            />
          } />
          <Route exact path='/projects/spaceinvader' component={() =>
            <ProjectDetailPage
              projectdetailname={t('ProjectLandingPage.spaceinvader')}
              projectdetailshortdesc={t('ProjectDetailPage.spaceinvadershortdesc')}
              projectdetaildesc={[t('ProjectDetailPage.spaceinvaderdesc'), <br />, <br />, <br />, <b>Time-Cost: 1months</ b>, <br />, <b>Skills: C++, QT, Game Design</b>]}
              coverimg={spaceinvader}
              projectsite="https://github.com/AlexSze/comp2012h-spaceinvader.git"
            />
          } />
          <Route exact path='/projects/theportfolio' component={() =>
            <ProjectDetailPage
              projectdetailname={t('ProjectLandingPage.theportfolio')}
              projectdetailshortdesc={t('ProjectDetailPage.theportfolioshortdesc')}
              projectdetaildesc={[t('ProjectDetailPage.theportfoliodesc'), <br />, <br />, <br />, <b>Time-Cost: 50hrs</ b>, <br />, <b>Skills: C++, styled component, Reactjs, GSAP, UI design, webapp animation, Hosting</b>]}
              coverimg={theportfolio}
              projectsite="/"
            />
          } />
          <Route exact path='/projects/mymarioplan' component={() =>
            <ProjectDetailPage
              projectdetailname={t('ProjectLandingPage.mymarioplan')}
              projectdetailshortdesc={t('ProjectDetailPage.mymarioplanshortdesc')}
              projectdetaildesc={[t('ProjectDetailPage.mymarioplandesc'), <br />, <br />, <br />, <b>Time-Cost: 8hrs</ b>, <br />, <b>Skills: Redux, Reactjs, Firebase, UI design, Hosting</b>]}
              coverimg={mymarioplan}
              projectsite="https://marioplan-e4135.firebaseapp.com"
            />
          } />
          <Route exact path='/projects/pacman' component={() =>
            <ProjectDetailPage
              projectdetailname={t('ProjectLandingPage.pacman')}
              projectdetailshortdesc={t('ProjectDetailPage.pacmanshortdesc')}
              projectdetaildesc={[t('ProjectDetailPage.pacmandesc'), <br />, <br />, <br />, <b>Time-Cost: 30hrs</ b>, <br />, <b>Skills: Python, Reinforcement learning, Neural Network, Artificial intelligence</b>]}
              coverimg={pacman}
              projectsite="https://github.com/AlexSze/cs3600-project-gt-2022Spring"
            />
          } />
          <Route exact path='/startups/picare' component={PicarePage} />
          <Route exact path='/startups/dearfans' component={DearFansPage} />
          <Route component={UnknownPage} />
        </Switch>
      </BrowserRouter>
    </Layout>
  );
}

export default App;
