import React from "react";
import styled from "styled-components";
import { ButtonStyle } from "./global/parts/buttons";

const UnknownPageStyled = styled.div`
    overflow: hidden;
    .canvas {
        padding: 0;
        margin: 0;
        background-color: var(--backgrounda);
        height: 100vh;
        width: 100vw;
    }
    .word{
        position: relative;
        font-size: var(--fontsizetitleb);
        font-weight: bold;
        text-align: center;
        top: 50vh;
        color: var(--texta);
        transform: translate(0, -50%);
        box-sizing: border-box;
        opacity: 1;
        margin-left: var(--marginhalf);
        margin-right: var(--marginhalf);
        @media (min-width: 1023px) {
            font-size: var(--fontsizetitlea);
        }
    }
`;

export default function UnknownPage(props) {
    return (
        <UnknownPageStyled>
            <div className="canvas">
                <div className="word">
                    404
                    <br />
                    <a href="/">
                        <ButtonStyle>
                            Back to Home
                        </ButtonStyle>
                    </a>
                </div>
            </div>
        </UnknownPageStyled>
    )
}