import React from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

const BorderStyled = styled.div`
    overflow: hidden;
    .canvas {
        background-color: var(--backgroundb);
        width: 100vw;
        height: var(--marginborder);
        position: fixed;
        top: 0;
        z-index: 55;
        display: none; 
        @media (min-width: 1023px) {
            display: block; 
        }
    }
    .sitename {
        background-color: transparent;
        color: var(--bordertext);
        font-size: var(--fontsizeborder);
        font-weight: bold;
        top: 2.5vh;
        left: var(--marginborder);
        position: fixed;
        z-index: 21;
        transform: translate(0%, -50%);
    }
    .buttoncontainer{
        top: 2.5vh;
        right: var(--marginborder);
        position: fixed;
        width: 5vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        z-index: 21;
        transform: translate(0%, -55%);
    }
    .button {
        background-color: transparent;
        color: var(--bordertext);
        font-size: var(--fontsizeborder);
        font-weight: bold;
        &:hover {
            color:var(--themecolora);
        }
        padding: 0px 0px;
        border: none;
        text-align: center;
        text-decoration: none;
        display: inline-block;
    }  
`;

const TopBoarder = ({ children }) => {
    const [t, i18n] = useTranslation();

    function handleClick(lang) {
        i18n.changeLanguage(lang);
    }

    return (
        <BorderStyled>
            <div className="canvas">
                <a href="/">
                    <div className="sitename">
                        {t('TopBorder.SiteName')}
                    </div>
                </a>
                <div className="buttoncontainer">
                    <button onClick={() => handleClick('chi')} className="button">
                        中
                    </button>
                    <button onClick={() => handleClick('en')} className="button">
                        EN
                    </ button>
                </div>
            </div>
        </BorderStyled>
    );
};

export default TopBoarder