import React, { useState, useEffect, useCallback } from 'react';

import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"

const PicarePageStyled = styled.div`
    .name{
        text-align: center;
        font-size: var(--fontsizetitleb);
        font-weight: 500;
        position: relative;
        color: red;
        background-color: transparent;
        opacity: 1;
        z-index: 5;
        /* padding: var(--margin); */
        width: 100vw;
        height: 100vh;
        top: 40vh;
        @media (min-width: 1023px) {
            font-size: var(--fontsizetitlea);
        }
    }
`;

function PicarePage() {

    return (
        < PicarePageStyled>
            <div className="name">
                Coming soon. This Page is under development.
            </ div>
        </ PicarePageStyled>
    )

}

export default PicarePage;