import React, { useState, useEffect, useCallback } from 'react';

import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useTranslation } from 'react-i18next';

import useWindowSize from './global/parts/windowsSize';
import { useHistory } from "react-router-dom";

import dearfanslogo from "../images/dearfanslogo.png";
import picarelogo from "../images/picarelogo.png";
import ibmlogo from "../images/ibmlogo.png";
import moxlogo from "../images/moxlogo.png";
import sclogo from "../images/sclogo.png";

gsap.registerPlugin(ScrollTrigger)

const AboutMePageStyled = styled.div`
    overflow: hidden;
    @media (min-width: 1023px) {
        .containermain{
            display: flex;
        }
    }
    .aboutmecanvas {
        min-width: 100vw;
        min-height: 100vh;
        background-color: var(--backgrounda);
        position: relative;
        padding-top: var(--marginhalf);
        padding-left: var(--marginborder);
        padding-right: var(--marginborder);
        z-index: 0;
        box-sizing: border-box;
    }
    .containerleft{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        opacity: 1;
        @media (min-width: 1023px) {
            padding-top: var(--marginborder);
            padding-bottom: var(--marginhalf);
            padding-left: var(--marginhalf);
            padding-right: var(--marginhalf);
            font-size: var(--fontsizexl);
        }
    }
    .containerright{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 20vw;
        padding-bottom: var(--marginborder);
        @media (min-width: 1023px) {
            padding-top: var(--marginborder);
            padding-bottom: var(--marginhalf);
            padding-left: var(--marginhalf);
            padding-right: var(--marginhalf);
            font-size: var(--fontsizexl);
        }
    }
    .containerskill{
        opacity: 1;
    }
    .containerwork{
        opacity: 1;
    }
    .title{
        font-size: var(--fontsizetitlec);
        font-weight: bold;
        position: relative;
        color: var(--texta);
        background-color: transparent;
        top: 0vh;
        left: 0vh;
        flex:1;
    }
    .bio{
        font-size: var(--fontsizecontentc);
        position: relative;
        color: var(--texta);
        background-color: transparent;
        top: 0vh;
        left: 0vh;
        padding-top: 3vh;
        padding-bottom: 3vh;
        flex: 5;
        text-align: justify;
        /* text-align: left; */
    }
    .skill{
        font-size: var(--fontsizecontentb);
        position: relative;
        color: var(--texta);
        background-color: transparent;
        top: 0vh;
        left: 0vh;
        margin-top: 3vh;
        margin-bottom: 3vh;
    }
    .skillbar{
        background-color: grey;
        position: relative;
        height: 1vh;
        width: 100%;
        margin-top: 1vh;
    }
    .skillbarprogressa{
        background-color: var(--themecolorc);
        position: relative;
        height: 1vh;
        margin-top: 1vh;
        width: 93%;
        animation: expandbara 1.5s ease-in-out 1;
        @keyframes expandbara{
            from{
                width: 0%;
            }
            to{
                width: 93%;
            }
        }
    }
    .skillbarprogressb{
        background-color: var(--themecolorc);
        position: relative;
        height: 1vh;
        margin-top: 1vh;
        width: 88%;
        animation: expandbarb 1.5s ease-in-out 1;
        @keyframes expandbarb{
            from{
                width: 0%;
            }
            to{
                width: 88%;
            }
        }
    }
    .skillbarprogressc{
        background-color: var(--themecolorc);
        position: relative;
        height: 1vh;
        margin-top: 1vh;
        width: 73%;
        animation: expandbarc 1.5s ease-in-out 1;
        @keyframes expandbarc{
            from{
                width: 0%;
            }
            to{
                width: 73%;
            }
        }
    }
    .skillbarprogressd{
        background-color: var(--themecolorc);
        position: relative;
        height: 1vh;
        margin-top: 1vh;
        width: 88%;
        animation: expandbard 1.5s ease-in-out 1;
        @keyframes expandbard{
            from{
                width: 0%;
            }
            to{
                width: 88%;
            }
        }
    }
    .skillbarprogresse{
        background-color: var(--themecolorc);
        position: relative;
        height: 1vh;
        margin-top: 1vh;
        width: 93%;
        animation: expandbare 1.5s ease-in-out 1;
        @keyframes expandbare{
            from{
                width: 0%;
            }
            to{
                width: 93%;
            }
        }
    }
    .workexperience{
        font-size: var(--fontsizecontentb);
        font-weight: bold;
        position: relative;
        text-align: center;
        align-items: center;
        background-color: var(--themecolorc);
        color: var(--backgrounda);
        min-width: 13vh;
        min-height: 13vh;
        margin-top: 3vh;
        margin-bottom: 3vh;
        padding: 2vh;
        border-radius: 15px;
        box-shadow: 0 5px 30px var(--bordertext);
    }
    .workexperienceduration{
        font-size: var(--fontsizecontentc);
        font-weight: 300;
        color: var(--backgrounda);
    }
    .dearfanslogo{
        margin: auto;
        margin-bottom: 2vh;
        min-height: var(--fontsizetitlec);
        width: var(--fontsizetitleb);
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat; 
        background-image: url(${dearfanslogo});
        background-size: 100%;
        box-sizing: border-box;
    }
    .picarelogo{
        margin: auto;
        margin-bottom: 2vh;
        min-height: var(--fontsizetitlec);
        width: var(--fontsizetitleb);
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat; 
        background-image: url(${picarelogo});
        background-size: 100%;
        box-sizing: border-box;
    }
    .ibmlogo{
        margin: auto;
        margin-bottom: 1vh;
        min-height: var(--fontsizetitlec);
        width: var(--fontsizetitleb);
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat; 
        background-image: url(${ibmlogo});
        background-size: 100%;
        box-sizing: border-box;
    }
    .moxlogo{
        margin: auto;
        margin-right: 10px;
        margin-bottom: 1vh;
        min-height: var(--fontsizetitlec);
        width: var(--fontsizetitleb);
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat; 
        background-image: url(${moxlogo});
        background-size: 100%;
        box-sizing: border-box;
    }
    .sclogo{
        margin: auto;
        margin-left: 0;
        margin-bottom: 1vh;
        min-height: var(--fontsizetitlec);
        width: var(--fontsizetitleb);
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat; 
        background-image: url(${sclogo});
        background-size: 100%;
        box-sizing: border-box;
    }
    .sitenamecontainer {
        background-color: transparent;
        color: var(--texta);
        font-size: var(--fontsizeborder);
        font-weight: bold;
        text-align: right;
        position: relative;
        z-index: 5;
        padding: 0;
        padding-bottom: var(--marginhalf);
        margin: auto;
        @media (min-width: 1023px) {
            display: none;
        }
    }
    .sitename{
        background-color: transparent;
        color: var(--texta);
    }
    .side-by-side {
        display: flex;
    }
`;

function AboutMePage() {
    const size = useWindowSize();
    const [t, i18n] = useTranslation();
    const history = useHistory();

    return (
        <AboutMePageStyled>
            <div className="aboutmecanvas" >
                <div className="sitenamecontainer">
                    <a href="/" style={{ textDecoration: "none" }}>
                        <div className='sitename'>
                            {t('TopBorder.SiteName')}
                        </div>
                    </a>
                </div>
                <div className="containermain">
                    <div className="containerleft">
                        <div className="title" >
                            {t('AboutMePage.titlea')}<br />{t('AboutMePage.titleb')}
                        </div>
                        <div className="bio">
                            <div dangerouslySetInnerHTML={{ __html: t('AboutMePage.bio') }} />
                            <a onClick={() => { history.push('/contact'); }}>
                                <u>{t('AboutMePage.biohere')}</u>
                            </ a>
                            {t('AboutMePage.bio2')}
                        </div>
                    </div>
                    <div className="containerright">
                        <div className="containerskill">
                            <div className="skill">
                                {t('AboutMePage.skilla')}
                                <div className="skillbar">
                                    <div className="skillbarprogressa" />
                                </div>
                            </div>
                            <div className="skill">
                                {t('AboutMePage.skillb')}
                                <div className="skillbar">
                                    <div className="skillbarprogressb" />
                                </div>
                            </div>
                            <div className="skill">
                                {t('AboutMePage.skillc')}
                                <div className="skillbar">
                                    <div className="skillbarprogressc" />
                                </div>
                            </div>
                            <div className="skill">
                                {t('AboutMePage.skilld')}
                                <div className="skillbar">
                                    <div className="skillbarprogressd" />
                                </div>
                            </div>
                            <div className="skill">
                                {t('AboutMePage.skille')}
                                <div className="skillbar">
                                    <div className="skillbarprogresse" />
                                </div>
                            </div>
                        </div>
                        <div className="containerwork">
                            <div className="workexperience">
                                <div class="side-by-side">
                                    <div className="moxlogo" />
                                    <div className="sclogo" />
                                </div>
                                <div>
                                    {t('AboutMePage.workexperienceda')}
                                </div>
                                <div>
                                    {t('AboutMePage.workexperiencedb')}
                                </div>
                                <div className='workexperienceduration'>
                                    {t('AboutMePage.workexperiencedc')}
                                </div>
                            </div>
                            <div className="workexperience">
                                <div className="ibmlogo" />
                                <div>
                                    {t('AboutMePage.workexperienceca')}
                                </div>
                                <div>
                                    {t('AboutMePage.workexperiencecb')}
                                </div>
                                <div className='workexperienceduration'>
                                    {t('AboutMePage.workexperiencecc')}
                                </div>
                            </div>
                            <div className="workexperience">
                                <div className="dearfanslogo" />
                                <div>
                                    {t('AboutMePage.workexperienceba')}
                                </div>
                                <div className='workexperienceduration'>
                                    {t('AboutMePage.workexperiencebb')}
                                </div>
                            </div>
                            <div className="workexperience">
                                <div className="picarelogo" />
                                <div>
                                    {t('AboutMePage.workexperienceaa')}
                                </div>
                                <div className='workexperienceduration'>
                                    {t('AboutMePage.workexperienceab')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AboutMePageStyled>
    )
}

export default AboutMePage;