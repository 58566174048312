import React, { useState, useEffect, useCallback } from 'react';

import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"

import useWindowSize from '../global/parts/windowsSize';
import { ButtonStyle } from '../global/parts/buttons';

const DearFansPageStyled = styled.div`
    overflow: hidden;
    .canvas {
        width: 100vw;
        min-height: 175vh;
        background-color: var(--backgroundb);
        position: relative;
        padding-bottom: var(--margin);
        top: 0vh;
        right: 0vw;
        z-index: 0;
    }
    .logo{
        width: 40vw;
        height: 40vw;
        top: var(--marginborder);
        right: var(--marginborder);
        position: absolute;
        background-image: url(${props => props.img});
        background-color: red;
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;
        box-sizing: border-box;
        opacity: 1;
        z-index: 1;
    }
    /* ratio 2880*1638 */
    .startuppicture{
        min-width: 70vw;
        min-height: 39.8125vw;
        top: 80vh;
        margin-left: 30vw;
        position: absolute;
        background-image: url(${props => props.img});
        background-color: red;
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;
        box-sizing: border-box;
        opacity: 1;
        z-index: 1;
        border-radius: 30px;
    }
    .startupname{
        text-align: left;
        font-size: var(--fontsizetitlea);
        font-weight: 500;
        position: absolute;
        color: var(--textb);
        background-color: transparent;
        top: 30vh;
        left: 13vw;
        opacity: 1;
        z-index: 5;
    }
    .startupshortdesc{
        text-align: left;
        font-size: var(--fontsizecontenta);
        font-weight: 3;
        position: absolute;
        color: var(--textb);
        background-color: transparent;
        margin: 1vh;
        opacity: 1;
        z-index: 5;
    }
    .startupdetaildesc{
        text-align: left;
        font-size: var(--fontsizecontenta);
        font-weight: 20;
        position: absolute;
        color: var(--texta);
        background-color: var(--backgrounda);
        width: 40vw;
        padding: 7vh;
        padding-left: 13vh;
        top: 107vh;
        opacity: 0;
        z-index: 15;
        object-fit: cover;
        border-radius: 30px;
        box-shadow: 0 30px 40px var(--bordertext);
    }
    .button{
        margin-top: 7vh;
        margin-bottom: 4vh;
    }
`;

function DearFansPage() {
    const size = useWindowSize();

    // gsap.to('.startupname, .startupshortdesc', {
    //     scrollTrigger: {
    //         markers: false,
    //         trigger: ".startupname",
    //         toogleActions: "restart pause reverse pause",
    //         start: "-50% top",
    //         end: "+=100%",
    //         scrub: 1,
    //     },
    //     top: '-=13vh',
    //     opacity: 0,
    // })

    gsap.to('.startupdetaildesc', {
        scrollTrigger: {
            markers: false,
            trigger: ".startupdetaildesc",
            toogleActions: "restart pause reverse pause",
            start: "5% 78%",
            end: "+=13%",
            scrub: 1,
        },
        top: '+=3vh',
        opacity: 1,
    })

    return (
        <DearFansPageStyled>
            <div className="canvas">
                <div className="logo" />
                <div className="startupname">
                    DearFans
                    <div className="startupshortdesc">
                        DearFandasdad
                    </div>
                </div>
                <div className="startuppicture" />
                <div className="startupdetaildesc">
                    yoyo
                    <br />
                    <a href="/">
                        <div className='button'>
                            <ButtonStyle>Visit Site</ButtonStyle>
                        </div>
                    </a>
                </div>
            </div>
        </DearFansPageStyled>
    )

}

export default DearFansPage;