import React, { useRef, useEffect, useCallback } from 'react';

import styled from "styled-components";
import { gsap } from "gsap";
import { useTranslation } from 'react-i18next';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { FaLongArrowAltRight } from 'react-icons/fa';

import businesscard from '../../images/businesscard.png';

gsap.registerPlugin(ScrollTrigger)

const ContactMeStyled = styled.div`
    .canvas {
        min-height: 100vh;
        background-color: var(--backgrounda);
        position: relative;
        padding-left: var(--marginhalf);
        padding-right: var(--marginhalf);
        z-index: 0;
        box-sizing: border-box;
    }
    .businesscard{
        /* 50vh + footer height */
        top: 60vh; 
        left: 50vw;
        /* ratio is 1050*600 */
        width: 78.75vw; 
        height: 45vw;
        padding: var(--marginhalf);
        position: absolute;
        background-color: var(--backgroundb);
        background-position:center center;
        background-image: url(${businesscard});
        background-size: cover;
        background-repeat: no-repeat;
        box-sizing: border-box;
        opacity: 1;
        z-index: 1;
        border-radius: 20px;
        box-shadow: 0 30px 40px var(--bordertext);
        transform: translate(-50%, -50%);
        @media (min-width: 1023px) {
            width: 78.75vh; 
            height: 45vh;
        }
    }
    .aboutusarrow {
        position: absolute;
        top: 23vh;
        right: 5vw;
        transform: rotate(270deg);
        @media (min-width: 1023px) {
            top: 36vh;
        }
    }
    .arrow {
        font-size: 1vh;
        font-weight: bold;
        color: var(--texta);
        width: 150px;
        animation: slide2 2s ease-in-out infinite;
        display: flex;
        align-items: center;
        @media (min-width: 1023px) {
            animation: slide1 2s ease-in-out infinite;
        }
    }
    @keyframes slide1 {
        0%,
        100% {
            transform: translate(0, 0);
        }
        50% {
            transform: translate(100px, 0);
        }
    }
    @keyframes slide2 {
        0%,
        100% {
            transform: translate(0, 0);
        }
        50% {
            transform: translate(60px, 0);
        }
    }
`;

function ContactMe() {
    const [t, i18n] = useTranslation();

    return (
        <ContactMeStyled>
            <div className="canvas">
                <div className="businesscard" />
                <div className="aboutusarrow">
                    <div className=" arrow">
                        {t('ContactMeSection.aboutmearrow')}
                        <FaLongArrowAltRight style={{ margin: '1vw', fontSize: '1.5vh' }} />
                    </div>
                </div>
            </div>
        </ContactMeStyled>
    );
}

export default ContactMe;