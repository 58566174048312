import React, { useState, useEffect, useCallback } from 'react';

import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from 'react-i18next';

import useWindowSize from '../global/parts/windowsSize';
import { ButtonStyle } from '../global/parts/buttons';

import aboutmecover from '../../images/aboutmecover.jpg';
// import aboutmecover from '../../images/meme.gif';

gsap.registerPlugin(ScrollTrigger)

const AboutMeStyled = styled.div`
overflow: hidden;
@media (min-width: 1023px) {
    .containermain{
        display: flex;
        flex-direction: column;
    }
}
.aboutmesectioncanvas {
    width: 100%;
    height: 100vh;
    background-color: var(--backgrounda);
    position: relative;
}
.aboutmepicture{
    top: 50vh;
    transform:translate(0, -50%);
    position:relative;
    background-color: var(--themecolorc);
    background-position:bottom bottom;
    background-image: url(${aboutmecover});
    background-size: 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    opacity: 1;
    z-index: 1;
    object-fit: cover;
    width: 100vw;
    height: 80vh;
    box-shadow: 0 30px 40px var(--bordertext);
    @media (min-width: 1023px) {
        background-position:center center;
        position: absolute;
        width: 38vw;
        height: 80vh;
        left: var(--margin);
        border-radius: 30px;
    }
}
.aboutmecontentcontainer{
    position: relative;
    top: 0;
    right: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: center;
    opacity: 0;
    z-index: 5;
    padding: var(--marginhalf) var(--marginhalf) var(--marginhalf) var(--marginhalf);
    @media (min-width: 1023px) {
        top: 10vh;
        position: absolute;
        background-color: transparent;
        padding: var(--margin) var(--margin) var(--margin) 15vh;
        width: 50vw;
        height: 100vh;
    }
}
.aboutmeword{
    text-align: center;
    font-size: var(--fontsizetitlec);
    font-weight: bold;
    position: relative;
    color: var(--texta);
    background-color: transparent;
    padding-bottom: 3vh;
    @media (min-width: 1023px) {
        text-align: left;
        font-size: var(--fontsizetitlea);
    }
}
.aboutmecontent{
    text-align: center;
    font-size: var(--fontsizecontentc);
    font-weight: 10;
    position: relative;
    color: var(--backgroundb);
    background-color: transparent;
    top: 7vh;
    @media (min-width: 1023px) {
        text-align: left;
    }
}
.button{
    position: relative;
}
`;

function AboutMe({ theref, jumpToBottom }) {
    const size = useWindowSize();
    const [t, i18n] = useTranslation();

    gsap.to('.aboutmecontentcontainer', {
        scrollTrigger: {
            markers: false,
            trigger: ".aboutmeword",
            toogleActions: "restart pause reverse pause",
            start: "top 50%",
            end: "+=5%",
            scrub: 1,
        },
        opacity: 1,
    })

    return (
        < AboutMeStyled >
            <div className="aboutmesectioncanvas" ref={theref}>
                <div className="containermain">
                    <div className="aboutmecontentcontainer">
                        <div className="aboutmecontent">
                            <div className="aboutmeword">
                                {t('AboutmeSection.title')}
                            </div>
                            <i>{t('AboutmeSection.descriptiona')}</i>
                            <br />
                            {t('AboutmeSection.descriptionb')}
                            <br />
                            <br />
                            <br />
                            <br />
                            <a href="/aboutme/">
                                <div className="button">
                                    <ButtonStyle>
                                        {t('AboutmeSection.learnmore')}
                                    </ButtonStyle>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="aboutmepicture" />
            </div>
        </AboutMeStyled >
    )
}

export default AboutMe;