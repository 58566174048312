import React, { useState, useEffect, useCallback } from 'react';

import styled from "styled-components";
import { useTranslation } from 'react-i18next';

import useWindowSize from '../global/parts/windowsSize';
import { FaLongArrowAltLeft } from 'react-icons/fa';

const StartupLandingPageStyled = styled.div`
    overflow: hidden;
    .canvas {
        margin: 0;
        width: 100vw;
        min-height: 100vh;
        background-color: var(--bordertext);
        position: absolute;
        padding: var(--marginhalf);
        box-sizing: border-box;
        @media (min-width: 1023px) {
            padding: var(--margin);
        }
    }
    .sitenamecontainer {
        background-color: transparent;
        color: var(--backgroundb);
        font-size: var(--fontsizeborder);
        font-weight: bold;
        text-align: right;
        position: relative;
        z-index: 5;
        top: -5vh;
        right: -5vh;
        padding-bottom: var(--marginhalf);
        margin: auto;
        @media (min-width: 1023px) {
            display: none;
        }
    }
    .sitename{
        background-color: transparent;
        color: var(--backgroundb);
    }
    .startupname{
        text-align: left;
        font-size: var(--fontsizetitleb);
        font-weight: 20;
        position: relative;
        color: var(--backgroundb);
        background-color: transparent;
        margin-bottom: var(--marginhalf);
        opacity: 1;
        transition: transform 0.3s ease;
        transform: translateX(0px);
        &:hover{
            transform: translateX(5vw);
        }
        @media (min-width: 1023px) {
            font-size: var(--fontsizetitlea);
        }
    }
    .startupshortdesc{
        text-align: left;
        font-size: var(--fontsizecontentc);
        font-weight: 20;
        position: absolute;
        color: #ed6459;
        background-color: transparent;
        margin: 1vh;
        opacity: 1;
        @media (min-width: 1023px) {
            font-size: var(--fontsizecontenta);
        }
    }
    .arrowback{
        position: relative;
        background-color: transparent;
        transition: transform 0.3s ease;
        transform: translateX(0vw);
        top: 3.3vh;
        left: -4vh;
        font-size: 3vh;
        z-index: 6;
        width: 20vw;
        background-color: transparent;
        color: var(--backgroundb);
        &:hover{
            transform: translateX(-1vw);
        }
        @media (min-width: 1023px) {
            top: -5vh;
            left: - var(--margin);
            font-size: 5vh;
        }
    }
`;

function StartupLandingPage() {
    const size = useWindowSize();
    const [t, i18n] = useTranslation();

    return (
        <StartupLandingPageStyled>
            <div className="canvas">
                <a href="/" style={{ textDecoration: "none" }}>
                    <div className=" arrowback">
                        <FaLongArrowAltLeft />
                    </div>
                </a>
                <div className="sitenamecontainer">
                    <a href="/" style={{ textDecoration: "none" }}>
                        <div className='sitename'>
                            {t('TopBorder.SiteName')}
                        </div>
                    </a>
                </div>
                <a href="https://developers.googleblog.com/2020/07/dsc-global-students-solutions-with-code.html" style={{ textDecoration: 'none' }}>
                    <div className="startupname">
                        {t('StartupLandingPage.picare')}
                        <div className="startupshortdesc">
                            {t('StartupLandingPage.picaredes')}
                        </div>
                    </div>
                </a>
                <a href="https://dearfans.com" style={{ textDecoration: 'none' }}>
                    <div className="startupname">
                        {t('StartupLandingPage.dearfans')}
                        <div className="startupshortdesc">
                            {t('StartupLandingPage.dearfansdes')}
                        </div>
                    </div>
                </a>
            </div>
        </StartupLandingPageStyled>
    )
}

export default StartupLandingPage;