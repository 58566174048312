import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';

import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useTranslation } from 'react-i18next';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { ImCross } from 'react-icons/im';

import useWindowSize from '../global/parts/windowsSize';

gsap.registerPlugin(ScrollTrigger)

const LandingSectionStyled = styled.div`
    overflow: hidden;
    .canvas {
        position: relative;
        width: 100vw;
        height: 100vh;
        background-color: var(--backgrounda);
        /* margin: auto; */
    }
    .helloworld{
        position: absolute;
        font-size: var(--fontsizetitlec);
        font-weight: bold;
        text-align: left;
        top: 45vh;
        color: var(--texta);
        transform: translate(0, -50%);
        box-sizing: border-box;
        opacity: 1;
        margin-left: var(--marginhalf);
        margin-right: var(--marginhalf);
        @media (min-width: 1023px) {
            top: 47vh;
            margin-left: var(--margin);
            margin-right: var(--margin);
            font-size: var(--fontsizexl);
        }
    }
    .title{
        padding-left: 0vh;
        font-size: var(--fontsizecontentc);
        font-weight: normal;
        color: var(--texta);
        @media (min-width: 1023px) {
            padding-left: 1vh;
            font-size: var(--fontsizecontenta);
        }
    }
    .aboutusarrow {
        position: absolute;
        bottom: 27vh;
        right: 0vw;
        transform: rotate(270deg);
        @media (min-width: 1023px) {
            right: 5vw;
            bottom: var(--margin);
        }
    }
    .arrow {
        font-size: 1vh;
        font-weight: bold;
        color: var(--texta);
        width: 150px;
        animation: slide2 2s ease-in-out infinite;
        display: flex;
        align-items: center;
        @media (min-width: 1023px) {
            animation: slide1 2s ease-in-out infinite;
        }
    }
    @keyframes slide1 {
        0%,
        100% {
            transform: translate(0, 0);
        }
        50% {
            transform: translate(100px, 0);
        }
    }
    @keyframes slide2 {
        0%,
        100% {
            transform: translate(0, 0);
        }
        50% {
            transform: translate(60px, 0);
        }
    }
    .menu {
        position: absolute;
        display: none;
        top: var(--marginhalf);
        left: var(--marginhalf);
        font-size: 3vh;
        color: var(--texta);
        z-index: 50;
        @media (min-width: 1023px) {
            display: block; 
        }
    }
    .overlaymenu{
        font-size: var(--fontsizecontenta);
        font-weight: normal;
        position: fixed;
        width: 100%;
        height: 100vh;
        color: var(--texta);
        background-color: var(--bordertext);
        opacity: 0.8;
        top: -100vh;
        transition: 1s;
        z-index: 30;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        text-align: center;
    }
    .menuitem{
        margin-bottom: var(--marginborder);
        text-decoration: none;
        &:hover {
            color:var(--themecolora);
        }
    }
    @keyframes fadeInRight {
        0% {
            opacity: 0;
            left: 20%;
        }
        100% {
            opacity: 1;
            left: 0;
        }
    }
`;

function LandingSection({ jumpToAboutMe, jumpToContactMe }) {
    // required by gsap
    const size = useWindowSize();
    const [t, i18n] = useTranslation();
    const [menu, toggleMenu] = useState(false);

    gsap.to(".helloworld", {
        scrollTrigger: {
            markers: false,
            trigger: ".helloworld",
            toogleActions: "restart pause reverse pause",
            start: "5% top",
            end: "7% top",
            scrub: 1,
        },
        ease: "none",
        opacity: 0,
    })

    return (
        <LandingSectionStyled>
            <div className="canvas">
                <div className="helloworld">
                    {t('LandingSection.Hi')}<br />{t('LandingSection.Im')}
                    <div className="title">
                        {t('LandingSection.title')}
                    </div>
                </div>
                <div className="menu">
                    {menu ? <ImCross style={{ position: "fixed" }} onClick={() => {
                        var menucss = document.getElementById("menucss")
                        menucss.style.top = '-100vh';
                        toggleMenu(false);
                    }} /> : <GiHamburgerMenu onClick={() => {
                        var menucss = document.getElementById("menucss")
                        menucss.style.top = '0vh';
                        toggleMenu(true);
                    }} />}
                </div>
                <div className="aboutusarrow" >
                    <div className=" arrow">
                        <FaLongArrowAltLeft style={{ margin: '1vw', fontSize: '1.5vh' }} />
                        {t('LandingSection.aboutmearrow')}
                    </div>
                </div>
                <div className="overlaymenu" id="menucss">
                    <a onClick={() => {
                        jumpToAboutMe();
                        var menucss = document.getElementById("menucss")
                        menucss.style.top = '-100vh';
                        toggleMenu(false);
                    }}>
                        <div className="menuitem">
                            {t('LandingSection.menuaboutme')}
                        </ div>
                    </a>
                    <Link to="/startups/" style={{ textDecoration: 'none', color: "var(--texta)" }}>
                        <div className="menuitem">
                            {t('LandingSection.menustartups')}
                        </div>
                    </Link>
                    <Link to="/projects/" style={{ textDecoration: 'none', color: "var(--texta)" }}>
                        <div className="menuitem">
                            {t('LandingSection.menuprojects')}
                        </div>
                    </Link>
                    <a onClick={() => {
                        jumpToContactMe();
                        var menucss = document.getElementById("menucss")
                        menucss.style.top = '-100vh';
                        toggleMenu(false);
                    }}>
                        <div className="menuitem">
                            {t('LandingSection.menucontactme')}
                        </div>
                    </a>
                </div>
            </div >
        </LandingSectionStyled >
    )
}

export default LandingSection;