import React, { useRef, useEffect, useCallback } from 'react';

import styled from "styled-components";
import { gsap } from "gsap";
import { useTranslation } from 'react-i18next';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { FaLongArrowAltRight } from 'react-icons/fa';

import businesscard from '../images/businesscard.png';

gsap.registerPlugin(ScrollTrigger)

const ContactPageStyled = styled.div`
    .canvas {
        min-height: 100vh;
        background-color: var(--backgrounda);
        position: relative;
        padding-left: var(--marginhalf);
        padding-right: var(--marginborder);
        z-index: 0;
        box-sizing: border-box;
    }
    .businesscard{
        /* 50vh + footer height */
        top: 50vh; 
        left: 50vw;
        /* ratio is 1050*600 */
        width: 78.75vw; 
        height: 45vw;
        padding: var(--marginhalf);
        position: absolute;
        background-color: var(--backgroundb);
        background-position:center center;
        background-image: url(${businesscard});
        background-size: cover;
        background-repeat: no-repeat;
        box-sizing: border-box;
        opacity: 1;
        z-index: 1;
        border-radius: 20px;
        box-shadow: 0 30px 40px var(--bordertext);
        transform: translate(-50%, -50%);
        @media (min-width: 1023px) {
            width: 78.75vh; 
            height: 45vh;
        }
    }
    .socialmediaarrow {
        position: absolute;
        top: 23vh;
        right: var(--marginhalf);
        display: none;
        @media (min-width: 1023px) {
            top: 47vh;
            right: var(--margin);
            display: block;
        }
    }
    .contactmearrow {
        position: absolute;
        top: 73vh;
        right: 5vw;
        transform: rotate(270deg);
        @media (min-width: 1023px) {
            top: 80vh;
            right: 52vw;
        }
    }
    .arrow {
        font-size: 1vh;
        font-weight: bold;
        color: var(--texta);
        width: 150px;
        animation: slide2 2s ease-in-out infinite;
        display: flex;
        align-items: center;
        @media (min-width: 1023px) {
            animation: slide1 2s ease-in-out infinite;
        }
    }
    .arrow2 {
        font-size: 1vh;
        font-weight: bold;
        color: var(--texta);
        width: 150px;
        animation: slide2 2s ease-in-out infinite;
        display: flex;
        align-items: center;
    }
    @keyframes slide1 {
        0%,
        100% {
            transform: translate(0, 0);
        }
        50% {
            transform: translate(100px, 0);
        }
    }
    @keyframes slide2 {
        0%,
        100% {
            transform: translate(0, 0);
        }
        50% {
            transform: translate(30px, 0);
        }
    }
    .sitenamecontainer {
        background-color: transparent;
        color: var(--texta);
        font-size: var(--fontsizeborder);
        font-weight: bold;
        text-align: right;
        position: relative;
        z-index: 5;
        padding: 0;
        padding-top: var(--marginhalf);
        margin: auto;
        @media (min-width: 1023px) {
            display: none;
        }
    }
    .sitename{
        background-color: transparent;
        color: var(--texta);
    }
`;

function ContactPage() {
    const [t, i18n] = useTranslation();

    return (
        <ContactPageStyled>
            <div className="canvas">
                <div className="sitenamecontainer">
                    <a href="/" style={{ textDecoration: "none" }}>
                        <div className='sitename'>
                            {t('TopBorder.SiteName')}
                        </div>
                    </a>
                </div>
                <div className="businesscard" />
                <div className="contactmearrow">
                    <div className=" arrow2">
                        {t('ContactPage.contactmearrow')}
                        <FaLongArrowAltRight style={{ margin: '1vw', fontSize: '1.5vh' }} />
                    </div>
                </div>
                <div className="socialmediaarrow">
                    <div className=" arrow">
                        {t('ContactPage.socialmediaarrow')}
                        <FaLongArrowAltRight style={{ margin: '1vw', fontSize: '1.5vh' }} />
                    </div>
                </div>
            </div>
        </ContactPageStyled>
    );
}

export default ContactPage;